$color-blue-gradient: linear-gradient(265.88deg, #0E8BBF 12.43%, #00ABED 84.85%);
$color-blue-primary: #168DBD;  
$color-blue-light: #CEF1FF;
$color-blue-dark: #2E7A95;
$color-yellow-secondary: #EC8000;
$color-green-secondary: #04A08B;
$color-blue-secondary: #044CA0;
$color-black: #2D2D2D;


