/* You can add global styles to this file, and also import other style files */
@import '~assets/styles/colors';
@import '~@angular/material/theming';
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@include mat-core();
// @import '~ngx-org-chart/_theming.scss';

html, body { height: 100%;  }
body {
	font-family: 'Manrope', sans-serif;
	font-size: 14px;
  padding: 0 !important;
  width: 100%;
}

.w-1 {
  width: 10%;
}

.w-15 {
  width: 15%;
}


.hvr-underline-from-left{
	&::before{
		background: $color-blue-primary;
	}
}

.btn-default{
	color: #919191 !important;
	background: #F4F4F4 !important;
}

.btn-primary{
  background: $color-blue-gradient;
  border: none;
  // border-radius: 100px;
  //min-width: 200px !important;
  display: inline-block !important;
  position: relative !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.form-control{
	border: none !important;
	background: transparent;
	border: 1px solid #DCF0FD !important;
	border-radius: 5px;
	font-family: 'Manrope';
	font-style: normal;
	font-weight:100 !important;
	color: #A0A0A0 !important;
	padding: 10px 15px;
	&::placeholder{
		color: #A0A0A0 !important;

	}
}

.form-group{
	position: relative;
	.img-plc{
		position: absolute;
		right: 10px;
		top: 31px;
		cursor: pointer;
	}
}

label{
	font-family: 'Manrope';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 19px;
	display: flex;
	align-items: center;
	color: #3C3C3C;
	margin-bottom: 7px !important;
}

/* SCROLL BAR */
::-webkit-scrollbar {
  width: 10px !important;
  height: 10px;
  cursor: move; /* fallback if grab cursor is unsupported */
}

@media (max-width: 800px){
  ::-webkit-scrollbar {
    width: 0px !important;
    height: 0px !important;
    cursor: move;
  }
}


::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

::-webkit-scrollbar-thumb {
    background: #28253d;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}


#toast-container{
  z-index: 999999999 !important;
}

.title-main{
	@media (max-width: 899px){
		font-size: 33px !important;
		line-height: 36px !important;
	}
}

.title-purchase{
	@media (max-width: 899px){
		font-size: 33px !important;
		line-height: 36px !important;
	}
}

.cdk-global-overlay-wrapper {
  overflow: auto;
  pointer-events: auto;  // needed for chrome but prevents backdrop click close
}

/* MAT DIALOG STYLING */
/* */
.mat-dialog-container,
.dialog-responsive {
    z-index: 99999 !important;
    /*border-color: white !important;
    border-radius: 8px !important;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05) !important;
    -webkit-box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05) !important;
    -moz-box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05) !important;
    overflow: auto !important;*/

    //position: absolute !important;
}

@media only screen and (max-width: 768px) {
    .mat-dialog-container,
    .dialog-responsive {
        width: 100% !important;
        margin: 0 !important;
        padding: 0 !important;
        height: auto;
        overflow: auto !important;
    }
}

/* Mat tooltip
.mat-tooltip {
  padding: 10px 15px 10px 10px !important;
  color: whitesmoke !important;
  margin-bottom: 20px !important;
  margin-left: 15px !important;
  white-space: pre-line;
  width: 150px;
  height: auto;
  font-size: 10px;
  font-weight: 300 !important;
  background: $color-purple;
  overflow: visible !important;
  bottom: -20px !important;
  border: none;
  border-radius: 8px !important;
  position: relative !important;
  right: -60px;
  top: 10px;
  z-index: 9999999 !important;
  box-shadow: 0px 4px 5px rgba(102, 102, 102, 0.25);

  &:after {
    content: " ";
    position:absolute;
    bottom:-7px;
    left: 5%;
    z-index: 99999999 !important;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 7px 0 7px;
    border-color: $color-purple transparent transparent transparent;
  }
}
*/

.custom-dialog-container {
  .mat-dialog-container {
    background: #EFFFFD;
  }
}

//Images Uploader
.btn-uploader {
  background: transparent;
  border: 0;
  color: #0E8BBF;
  padding: 0;
  font-size: 13px;
  // font-family: $semi-bold;
}

.div {
  &--color {
      display: flex;
      column-gap: 10px;
      flex-direction: row;
  }
  &--upload {
      height: auto;
      border: 1px dashed #C0C0C0;
      border-radius: 8px;
      text-align: center;
      padding: 10px;
      align-items: center;
      justify-content: center;
      display: flex;
      &.disabled {
        background-color: #DCF0FD;
        opacity: 0.5 !important;
      }
      p {
          margin-bottom: 0;
          color: grey;
          font-size: 13px;
      }
  }
}
